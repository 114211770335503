<script setup lang="ts">
import type { FreeSpinData } from "@/types";

const { t } = useT();

const props = defineProps<{
	data: FreeSpinData;
	isFreePlayMode?: boolean;
}>();

const emit = defineEmits<{ (event: "clickFreeSpin"): void }>();

const { spinsData: socketSpinsData } = useFreeSpinSockets();
const { gameId, betLevel } = props.data;
const spinsRemain = computed(() =>
	gameId === socketSpinsData?.value?.data?.gameId ? socketSpinsData?.value?.data?.spinsRemain : props.data.spinsRemain
);
const spinsTotal = computed(() =>
	gameId === socketSpinsData?.value?.data?.gameId ? socketSpinsData?.value?.data?.spinsTotal : props.data.spinsTotal
);

const badgeText = computed(() =>
	props.isFreePlayMode ? t("freeSpin.promo.badge.name1") : t("freeSpin.promo.badge.name2")
);

const { open } = useFunrizeModals();
const { handleOpenGame } = useOpenGame(open);
useGetGameFiltersData();
const { select } = useGamesState();
const game = computed(() => select([gameId])[0]);
const progress = computed(() => `calc(${(100 * (spinsRemain?.value || 0)) / (spinsTotal?.value || 1)}% - 3px)`);

const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const handleCardClick = () => {
	emit("clickFreeSpin");

	if (!game) {
		return;
	}

	if (!isSweepStakes?.value) {
		handleSwitchGameMode();
	}

	handleOpenGame(game.value?.slug);
};
</script>

<template>
	<ACard height="257px" @click="handleCardClick">
		<template #top>
			<MGamePromotionPreview v-if="game" :game="game" />
			<ABadge background="var(--chusovoy)" color="var(--chomutov)" variant="skew" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ badgeText }}</AText>
			</ABadge>
			<div v-if="spinsTotal" class="fs-progress">
				<div :class="['icon', { 'free-spin-quest': isFreePlayMode }]">
					<div class="inner">
						<NuxtImg src="/nuxt-img/promotions/fp.png" format="webp" alt="free play" width="24" height="24" />
					</div>
				</div>
				<div class="bar">
					<AText class="text" :modifiers="['bold', 'uppercase']">{{
						t("{amount} FS left", { amount: spinsRemain })
					}}</AText>
				</div>
			</div>
			<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="text flex-line">
				<i18n-t v-if="betLevel" keypath="play level - {icon} {betLevel}">
					<template #icon>
						<NuxtImg
							src="/nuxt-img/cards/entries.png"
							alt="entries"
							format="webp"
							width="16"
							height="16"
							loading="lazy"
						/>
					</template>
					<template #betLevel>{{ numberFormat(betLevel) }}</template>
				</i18n-t>
			</AText>
			<AText v-if="game" variant="ternopil" :modifiers="['normal', 'uppercase']" class="text">
				<i18n-t keypath="on {title}">
					<template #title>
						<span v-html="game.title" />
					</template>
				</i18n-t>
			</AText>
		</template>
		<template #bottom>
			<AButton variant="primary">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Continue playing") }}</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.text {
	max-width: 175px;
}

:deep(.badge) {
	position: absolute;
	left: 0;
	top: 0;
}

button {
	width: 240px;
	height: 40px;
	gap: gutter(0.75);
}

.fs-progress {
	position: relative;
	margin-bottom: 3px;
	display: flex;
	width: 193px;
	align-items: center;

	.icon {
		width: 38px;
		height: 38px;
		background-image: linear-gradient(to bottom, var(--carrollton), var(--carsamba));
		box-shadow: 4px 0px 2px rgba(0, 0, 0, 0.1);
		border-radius: 50%;
		padding: 3px;
		z-index: 1;

		&.free-spin-quest {
			background-image: linear-gradient(to bottom, var(--chusovoy), var(--carsamba));
		}

		.inner {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			background: var(--choloma);
			border-radius: 50%;

			img {
				margin-top: 3px;
			}
		}
	}

	.bar {
		height: 24px;
		position: relative;
		padding: 3px 35px 3px 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		margin-left: -5px;

		&:before {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			content: "";
			background: var(--culiacan);
			opacity: 0.3;
			border-radius: 0 12px 12px 0;
		}

		&:after {
			display: block;
			content: "";
			position: absolute;
			left: 0;
			top: 3px;
			bottom: 3px;
			background-image: linear-gradient(to right, var(--carsamba), var(--chusovoy));
			width: v-bind(progress);
			border-radius: 0 9px 9px 0;
		}

		.text {
			position: relative;
			z-index: 1;
			text-shadow: 0px 2px 2px var(--coronel);
		}
	}
}

.flex-line {
	display: flex;
	align-items: center;

	img {
		margin: 0 2px;
	}
}
</style>
